/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Container, Box, Flex, NavLink } from "@theme-ui/components";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid, Divider, Button, Card, Text } from "@theme-ui/components";
import NavBar from "../components/NavBar";
import { graphql } from "gatsby";
import { Link as GatsbyLink } from "gatsby";
import { Link } from "@theme-ui/components";
import Seo from "../components/seo";

const index = ({ data }) => {
	const posts = data.allMdx.nodes;
	console.log(posts);
	return (
		<div>
			<Seo />
			<NavBar />
			<div
				sx={{
					width: "100vw",
					minWidth: "100vw",
					minHeight: "90vh",
					backgroundColor: "primary",
					position: "relative",
				}}
			>
				<StaticImage
					src="../images/ame-hero-v2a.png"
					alt=""
					objectFit="cover"
					objectPosition="100% 0%"
					style={{
						position: "absolute",
						zIndex: 1,
						height: "100%",
						width: "100%",
					}}
				/>

				<div
					sx={{
						backgroundColor: "background",
						opacity: 0.8,
						height: "100%",
						width: "100%",
						position: "absolute",
						zIndex: 2,
					}}
				></div>

				<Container
					sx={{
						height: "100%",
						zIndex: 100,
						position: "relative",
					}}
				>
					<Box py={6} color="white">
						<Grid gap={2} columns={[2, "3fr 1fr"]}>
							<Box>
								<h1
									sx={{
										color: "white",
										fontSize: "48px",
										fontWeight: "500",
										lineHeight: "1",
									}}
								>
									New
									<span
										sx={{
											color: "secondary",
										}}
									>
										{" "}
										Asset Management Education
									</span>{" "}
									Courses updated regularly.
								</h1>
								<Divider
									sx={{
										backgroundColor: "primary",
									}}
								/>
							</Box>
							<Box></Box>
						</Grid>

						<h2>
							We are committed to creating new courses that will continue to further your organizations condition monitoring program. 
						</h2>
						<p>
							At AME Systems, our partnerships with industry subject matter experts like SDT Ultrasound Solutions & Conscious Reliability make consistently releasing new asset management courses possible. Thanks to our partners unbridled experience in their respective fields, we are able to create comprehensive, detailed, and meaningful courses our students can enjoy, learn from, and utilize to become better at their job.
						</p>

						<Button as={GatsbyLink} to="contact" mr={2}>
							Want to be an Instructor?
						</Button>
					</Box>
				</Container>
			</div>
			<Container>
				<Box py={4}>
					<Text py={3} as="h2">
						AME System Courses
					</Text>
					<Grid width={[192, null, 192]}>
						{posts.map((post) => {
							return (
								<Card
									key={post.slug}
									sx={{
										maxWidth: "100%",
										padding: 1,
										borderRadius: 2,
										border: "1px solid",
										borderColor: "primary",
									}}
								>
									<GatsbyImage
										image={getImage(post.frontmatter.featuredImage)}
									/>
									<Text
										as="h3"
										p={2}
										sx={{
											color: "secondary",
										}}
									>
										{post.frontmatter.title}
									</Text>
									<Text
										as="p"
										p={2}
										sx={{
											color: "text",
											fontSize: "12px",
										}}
									>
										{post.frontmatter.company}
									</Text>
									<Text
										as="p"
										p={2}
										sx={{
											color: "text",
										}}
									>
										{post.frontmatter.excerpt}
									</Text>
									<Link
										p={2}
										sx={{ color: "primary" }}
										to={"/" + post.slug}
										as={GatsbyLink}
									>
										Read More...
									</Link>
								</Card>
							);
						})}
					</Grid>
				</Box>
			</Container>
		</div>
	);
};

export default index;

export const pageQuery = graphql`
	query {
		allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				id
				slug
				excerpt(pruneLength: 250)
				frontmatter {
					category
					excerpt
					author
					company
					featuredImage {
						childImageSharp {
							gatsbyImageData(
								width: 1200
								placeholder: BLURRED
								formats: [AUTO, WEBP, AVIF]
							)
						}
					}
					title
					date(formatString: "YYYY MMMM Do")
					modules {
						moduleNumber
						title
						content
					}
				}
			}
		}
	}
`;
